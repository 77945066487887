// src/components/icons.js

import React from 'react';

export function CoffeeIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<path d='M10 2v2' />
			<path d='M14 2v2' />
			<path d='M16 8a1 1 0 0 1 1 1v8a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V9a1 1 0 0 1 1-1h14a4 4 0 1 1 0 8h-1' />
			<path d='M6 2v2' />
		</svg>
	);
}

export function HomeIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<path d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z' />
			<polyline points='9 22 9 12 15 12 15 22' />
		</svg>
	);
}

export function InstagramIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<rect
				width='20'
				height='20'
				x='2'
				y='2'
				rx='5'
				ry='5'
			/>
			<path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z' />
			<line
				x1='17.5'
				x2='17.51'
				y1='6.5'
				y2='6.5'
			/>
		</svg>
	);
}

export function MonitorIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<rect
				width='20'
				height='14'
				x='2'
				y='3'
				rx='2'
			/>
			<line
				x1='8'
				x2='16'
				y1='21'
				y2='21'
			/>
			<line
				x1='12'
				x2='12'
				y1='17'
				y2='21'
			/>
		</svg>
	);
}

export function ShoppingBagIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<path d='M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z' />
			<path d='M3 6h18' />
			<path d='M16 10a4 4 0 0 1-8 0' />
		</svg>
	);
}

export function ShoppingCartIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<circle
				cx='8'
				cy='21'
				r='1'
			/>
			<circle
				cx='19'
				cy='21'
				r='1'
			/>
			<path d='M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12' />
		</svg>
	);
}

export function MenuIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
			stroke-width='1.5'
			stroke='#000'
		>
			<path
				stroke-linecap='round'
				stroke-linejoin='round'
				d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
			/>
		</svg>
	);
}

export function CartIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 25 25'
			fill='none'
		>
			<path
				d='M0 2.34375C0 1.91228 0.349778 1.5625 0.78125 1.5625H3.125C3.48349 1.5625 3.79598 1.80648 3.88292 2.15427L4.51623 4.6875H22.6562C22.8903 4.6875 23.112 4.79244 23.2604 4.97345C23.4088 5.15445 23.4682 5.39245 23.4223 5.62197L21.8598 13.4345C21.7897 13.7853 21.4902 14.0436 21.1329 14.0615L6.45034 14.7979L6.89838 17.1875H20.3125C20.744 17.1875 21.0938 17.5373 21.0938 17.9688C21.0938 18.4002 20.744 18.75 20.3125 18.75H18.75H7.8125H6.25C5.87405 18.75 5.55141 18.4822 5.48213 18.1127L3.14267 5.63563L2.51502 3.125H0.78125C0.349778 3.125 0 2.77522 0 2.34375ZM4.8476 6.25L6.15973 13.2481L20.447 12.5315L21.7033 6.25H4.8476ZM7.8125 18.75C6.08661 18.75 4.6875 20.1491 4.6875 21.875C4.6875 23.6009 6.08661 25 7.8125 25C9.53839 25 10.9375 23.6009 10.9375 21.875C10.9375 20.1491 9.53839 18.75 7.8125 18.75ZM18.75 18.75C17.0241 18.75 15.625 20.1491 15.625 21.875C15.625 23.6009 17.0241 25 18.75 25C20.4759 25 21.875 23.6009 21.875 21.875C21.875 20.1491 20.4759 18.75 18.75 18.75ZM7.8125 20.3125C8.67544 20.3125 9.375 21.0121 9.375 21.875C9.375 22.7379 8.67544 23.4375 7.8125 23.4375C6.94955 23.4375 6.25 22.7379 6.25 21.875C6.25 21.0121 6.94955 20.3125 7.8125 20.3125ZM18.75 20.3125C19.6129 20.3125 20.3125 21.0121 20.3125 21.875C20.3125 22.7379 19.6129 23.4375 18.75 23.4375C17.8871 23.4375 17.1875 22.7379 17.1875 21.875C17.1875 21.0121 17.8871 20.3125 18.75 20.3125Z'
				fill='#417779'
			/>
		</svg>
	);
}
