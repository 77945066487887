import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import bookIcon from './assets/menu/book.png';
import coffeeIcon from './assets/menu/coffee.png';
import roomServiceIcon from './assets/menu/room-service.png';
import displayIcon from './assets/menu/display.png';
import houseIcon from './assets/menu/house.png';
import cartIcon from './assets/menu/cart.png';
import bagIcon from './assets/menu/bag.png';
import instagramIcon from './assets/menu/instagram.png';

function Menu({ toggleSidebar, isOpen }) {
	return (
		<>
			{isOpen && (
				<div
					className='overlay'
					onClick={toggleSidebar}
				></div>
			)}

			<nav className={`sidebar ${isOpen ? 'open' : ''}`}>
				<ul className='sidebar-list'>
					<li className='sidebar-item'>
						<Link to='/quem-somos'>
							<img
								src={bookIcon}
								alt='Ícone de um livro representando a seção de história'
								className='icon'
							/>
							<span>história</span>
						</Link>
					</li>
					<li className='sidebar-item'>
						<Link to='/'>
							<img
								src={coffeeIcon}
								alt='Ícone de uma xícara de café indicando a cafeteria'
								className='icon'
							/>
							<span>cafeteria</span>
						</Link>
					</li>
					<li className='sidebar-item'>
						<Link to='/almoco'>
							<img
								src={roomServiceIcon}
								alt='Ícone de um prato com tampa de serviço de quarto representando refeições'
								className='icon'
							/>
							<span>refeições</span>
						</Link>
					</li>
					<li className='sidebar-item'>
						<Link
							to='https://www.oond.com.br/espacos/link-cafe-hub'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src={displayIcon}
								alt='Ícone de um monitor de computador representando a área de coworking'
								className='icon'
							/>
							<span>coworking</span>
						</Link>
					</li>
					<li className='sidebar-item'>
						<Link
							to='https://api.whatsapp.com/message/5M5BJ5DLQEZ4M1?autoload=1&app_absent=0'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img
								src={houseIcon}
								alt='Ícone de uma casa representando a seção de eventos'
								className='icon'
							/>
							<span>eventos</span>
						</Link>
					</li>
					<li className='sidebar-item'>
						<Link to='/linkstore'>
							<img
								src={cartIcon}
								alt='Ícone de um carrinho de compras simbolizando a loja virtual'
								className='icon'
							/>
							<span>loja</span>
						</Link>
					</li>
					<li className='sidebar-item'>
						<a
							href='https://www.ifood.com.br/delivery/curitiba-pr/link-cafe-hub-sao-francisco/8ab6b26b-04e1-4440-a45a-1fc98859d37a'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src={bagIcon}
								alt='Ícone de uma sacola de compras sugerindo pedidos via iFood'
								className='icon'
							/>
							<span>ifood</span>
						</a>
					</li>
					<li className='sidebar-item'>
						<a
							href='https://www.instagram.com/link.cafehub/'
							target='_blank'
							rel='noreferrer'
						>
							<img
								src={instagramIcon}
								alt='Ícone do logo do Instagram representando o perfil da empresa'
								className='icon'
							/>
							<span>instagram</span>
						</a>
					</li>
				</ul>
			</nav>
		</>
	);
}

export default Menu;
