import photo1 from '../components/assets/gallery/1.png';
import photo3 from '../components/assets/gallery/3.png';
import photo4 from '../components/assets/gallery/4.png';
import photo5 from '../components/assets/gallery/5.png';
import photo6 from '../components/assets/gallery/6.png';
import photo7 from '../components/assets/gallery/7.png';
import photo8 from '../components/assets/gallery/8.png';

export const photos = [
	{
		src: photo1,
		alt: 'Fachada Link Café',
	},
	{
		src: photo3,
		alt: 'Cuscuz gourmet',
	},
	{
		src: photo4,
		alt: 'Interior Link Café',
	},
	{
		src: photo5,
		alt: 'Croissant com café',
	},
	{
		src: photo6,
		alt: 'Interior Link Café 2',
	},
	{
		src: photo7,
		alt: 'Interior Link Café 3',
	},
	{
		src: photo8,
		alt: 'Interior Link Café 4',
	},
];
