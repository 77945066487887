import React from 'react';
import './Footer.css';
import Carousel from './Carousel';
import { partners } from '../utils/partners';

const Footer = () => {
	return (
		<footer className='footer-container'>
			<Carousel partners={partners} />
		</footer>
	);
};

export default Footer;
