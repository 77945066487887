import React from 'react';
import './MenuPage.css';
import Menu from './assets/A4.png';

const MenuPage = () => {
	return (
		<div className='menu-page'>
			<img
				src={Menu}
				alt='Menu'
			/>
		</div>
	);
};

export default MenuPage;
