import React, { useState, useCallback, useEffect } from 'react';
import './Carousel.css';

import ChevronLeft from './assets/icons/chevron-left.svg';
import ChevronRight from './assets/icons/chevron-right.svg';

const Carousel = ({ partners }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [itemsToShow, setItemsToShow] = useState(4);

	const nextSlide = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % partners.length);
	}, [partners.length]);

	const prevSlide = useCallback(() => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + partners.length) % partners.length
		);
	}, [partners.length]);

	useEffect(() => {
		const updateItemsToShow = () => {
			if (window.innerWidth < 480) {
				setItemsToShow(1);
			} else if (window.innerWidth < 768) {
				setItemsToShow(2);
			} else if (window.innerWidth < 1024) {
				setItemsToShow(3);
			} else {
				setItemsToShow(5);
			}
		};

		updateItemsToShow();

		window.addEventListener('resize', updateItemsToShow);
		return () => {
			window.removeEventListener('resize', updateItemsToShow);
		};
	}, []);

	return (
		<div className='carousel-container'>
			<button
				className='carousel-button prev'
				onClick={prevSlide}
			>
				<img
					src={ChevronLeft}
					alt='Previous'
				/>
			</button>
			<div className='carousel'>
				<div className='carousel-content'>
					{[...Array(itemsToShow)].map((_, i) => {
						const index = (currentIndex + i) % partners.length;
						return (
							<div
								key={i}
								className='carousel-item'
							>
								<img
									src={partners[index].src}
									alt={partners[index].alt}
								/>
							</div>
						);
					})}
				</div>
			</div>
			<button
				className='carousel-button next'
				onClick={nextSlide}
			>
				<img
					src={ChevronRight}
					alt='Next'
				/>
			</button>
		</div>
	);
};

export default Carousel;
