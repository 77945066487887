import React from 'react';
import './LinkStorePage.css';
import storeImage from './assets/store/store-banner.png';
import product1 from './assets/store/product1.png';
import product2 from './assets/store/product2.png';
import product3 from './assets/store/product3.png';
import product4 from './assets/store/product4.png';
import product5 from './assets/store/product5.png';
import product6 from './assets/store/product6.png';
import product7 from './assets/store/product7.png';
import product8 from './assets/store/product8.png';
import { CartIcon } from './icons';

const LinkStorePage = () => {
	return (
		<>
			<img
				src={storeImage}
				alt='LinkStore Banner'
				className='linkstore-banner'
			/>
			<div className='linkstore-page'>
				<div className='side-img' />
				<main>
					<h2>LinkStore</h2>
					<div className='products-grid'>
						<div className='product-card'>
							<img
								src={product1}
								alt='Produto 1'
							/>
							<h3>Jogo 04 de copos</h3>
							<p>
								R$ 243,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product2}
								alt='Produto 2'
							/>
							<h3>Par xícaras pinas ovais</h3>
							<p>
								R$ 318,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product3}
								alt='Produto 3'
							/>
							<h3>Copo Araucária</h3>
							<p>
								R$ 110,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product4}
								alt='Produto 4'
							/>
							<h3>Jarra e coador</h3>
							<p>
								R$ 358,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product5}
								alt='Produto 5'
							/>
							<h3>Livro Cidades Dinâmicas</h3>
							<p>
								R$ 60,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product6}
								alt='Produto 6'
							/>
							<h3>Mini Jardim Botânico</h3>
							<p>
								R$ 30,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product7}
								alt='Produto 7'
							/>
							<h3>Chaveiro Pinhão</h3>
							<p>
								R$ 50,00 <CartIcon />
							</p>
						</div>
						<div className='product-card'>
							<img
								src={product8}
								alt='Produto 8'
							/>
							<h3>Colar Pinhão</h3>
							<p>
								R$ 50,00 <CartIcon />
							</p>
						</div>
					</div>
					<div className='linkstore-contact-form'>
						<h2>
							Seu Produto na <b>LinkStore</b>
						</h2>
						<form>
							<input
								type='text'
								placeholder='Seu nome'
								required
							/>
							<div class='flex-form'>
								<input
									type='email'
									placeholder='Seu e-mail'
									required
								/>
								<input
									type='tel'
									placeholder='Seu celular'
									required
								/>
							</div>
							<textarea
								placeholder='Descreva seu produto e forma de comercialização'
								required
							></textarea>
							<button type='submit'>Enviar Pedido</button>
						</form>
					</div>
				</main>
				<div className='side-img' />
			</div>
		</>
	);
};

export default LinkStorePage;
