import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './assets/logo.png';
import whatsappIcon from './assets/icons/whats.svg';
import homeIcon from './assets/home.png';
import cartIcon from './assets/cart.png';
import monitorIcon from './assets/monitor.png';
import coffeeIcon from './assets/coffee.png';
import documentIcon from './assets/document.png';
import foodIcon from './assets/food.png';
import instagramIcon from './assets/icons/instagram.svg';
import linkedinIcon from './assets/icons/linkedin.svg';
import ifoodIcon from './assets/icons/ifood.svg';
import infoLinkIcon from './assets/news.png';
import Menu from './Menu'; 
import { MenuIcon } from './icons'; 

function Header() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Menu
				toggleSidebar={toggleSidebar}
				isOpen={isOpen}  
			/>
			<header className='header'>
				<div className='header-content'>
					<div className='left-icons'>
						{/* Logo - Pagina principal */}
						<Link to='/'>
							<img
								src={logo}
								alt='Link Café Logo'
								className='logo'
							/>
						</Link>
						<div className='menu-icons'>
							{/* Documento - Quem Somos */}
							<Link to='/quem-somos'>
								<img
									src={documentIcon}
									alt='Document Icon'
									className='menu-icon'
								/>
							</Link>
							{/* Café - Cardápio */}
							<Link to='/menu'>
								<img
									src={coffeeIcon}
									alt='Coffee Icon'
									className='menu-icon'
								/>
							</Link>
							{/* Plate - Almoço */}
							<Link to='/almoco'>
								<img
									src={foodIcon}
									alt='Plate Icon'
									className='menu-icon'
								/>
							</Link>
							{/* Casa - Eventos (ainda não feito) */}
							<Link
								to='https://api.whatsapp.com/message/5M5BJ5DLQEZ4M1?autoload=1&app_absent=0'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={homeIcon}
									alt='Home Icon'
									className='menu-icon'
								/>
							</Link>
							{/* Monitor - Coworking (ainda não feito) */}
							<Link
								to='https://www.oond.com.br/espacos/link-cafe-hub'
								target='_blank'
								rel='noopener noreferrer'
							>
								<img
									src={monitorIcon}
									alt='Monitor Icon'
									className='menu-icon'
								/>
							</Link>
							{/* Carrinho de Compras - Loja */}
							<Link to='/linkstore'>
								<img
									src={cartIcon}
									alt='Cart Icon'
									className='menu-icon'
								/>
							</Link>
						</div>
					</div>
					<div className='right-icons'>
						<div className='infolink-container'>
							<a href='#infolink'>
								<img
									src={infoLinkIcon}
									alt='Info Link Icon'
									className='menu-icon'
								/>
							</a>
							{/* Caixa de email que aparece ao passar o mouse */}
							<div className='email-box'>
								<form>
									<input
										type='email'
										placeholder='Informe seu email para receber news'
										required
									/>
									<button type='submit'>OK</button>
								</form>
							</div>
						</div>
						<span className='separator' />
						<a
							href='https://www.ifood.com.br/delivery/curitiba-pr/link-cafe-hub-sao-francisco/8ab6b26b-04e1-4440-a45a-1fc98859d37a'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img
								src={ifoodIcon}
								alt='iFood Icon'
								className='menu-icon'
							/>
						</a>
						<a
							href='https://api.whatsapp.com/message/5M5BJ5DLQEZ4M1?autoload=1&app_absent=0'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img
								src={whatsappIcon}
								alt='WhatsApp Icon'
								className='menu-icon'
							/>
						</a>
						<a
							href='https://www.instagram.com/link.cafehub/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img
								src={instagramIcon}
								alt='Instagram Icon'
								className='menu-icon'
							/>
						</a>
						<a
							href='https://www.linkedin.com/company/link-caf%C3%A9-hub/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<img
								src={linkedinIcon}
								alt='LinkedIn Icon'
								className='menu-icon'
							/>
						</a>
					</div>
					<button
						onClick={toggleSidebar}
						className='menu-button'
					>
						<MenuIcon />
					</button>
				</div>
			</header>
		</>
	);
}

export default Header;
