import React, { useState, useEffect, useCallback } from 'react';
import './PhotoGallery.css';
import ChevronLeft from './assets/icons/chevron-left.svg';
import ChevronRight from './assets/icons/chevron-right.svg';

const PhotoGallery = ({ photos }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isTransitioning, setIsTransitioning] = useState(false);

	const nextSlide = useCallback(() => {
		if (!isTransitioning) {
			setIsTransitioning(true);
			setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
		}
	}, [photos.length, isTransitioning]);

	const prevSlide = useCallback(() => {
		if (!isTransitioning) {
			setIsTransitioning(true);
			setCurrentIndex(
				(prevIndex) => (prevIndex - 1 + photos.length) % photos.length
			);
		}
	}, [photos.length, isTransitioning]);

	useEffect(() => {
		let interval;

		const autoSlide = () => {
			if (!isTransitioning) {
				nextSlide();
			}
		};

		interval = setInterval(autoSlide, 3000);

		return () => clearInterval(interval);
	}, [nextSlide, isTransitioning]);

	const handleTransitionEnd = () => {
		setIsTransitioning(false);
	};

	return (
		<div className='gallery'>
			<button
				className='gallery-button prev'
				onClick={prevSlide}
				disabled={isTransitioning}
			>
				<img
					src={ChevronLeft}
					alt='Previous'
				/>
			</button>
			<div
				className='gallery-content'
				onTransitionEnd={handleTransitionEnd}
			>
				{photos.map((photo, index) => {
					return (
						<div
							key={index}
							className='gallery-item'
							style={{
								transform: `translateX(-${currentIndex * 100}%)`,
								transition: isTransitioning
									? 'transform .7s ease-in-out'
									: 'none',
							}}
						>
							<img
								src={photo.src}
								alt={photo.alt}
							/>
						</div>
					);
				})}
			</div>
			<button
				className='gallery-button next'
				onClick={nextSlide}
				disabled={isTransitioning}
			>
				<img
					src={ChevronRight}
					alt='Next'
				/>
			</button>
		</div>
	);
};

export default PhotoGallery;
