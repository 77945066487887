import React from 'react';
import './Plans.css';
import mapImage from './assets/map.png';
import sharedDeskImage from './assets/shared-desk.png';
import privateDeskImage from './assets/private-desk.png';
import meetingRoomImage from './assets/meeting-room.png';
import coffeeImage from './assets/coffee-image.png';

const Plans = () => {
	return (
		<div className='plans-section'>
			<h2>Planos de Assinatura</h2>
			<div className='plans-container'>
				<div className='plan-map'>
					<div className='plan-info'>
						<p>01 - MESAS COMPARTILHADAS (individual)</p>
						<span className='line'></span>
						<p>02- MESAS PRIVATIVAS (4 lugares)</p>
					</div>
					<img
						src={mapImage}
						alt='Mapa de Espaços'
					/>
					<div className='plan-info'>
						<p>03 - SALA PRIVATIVA (8 lugares)</p>
						<span className='line'></span>
						<p>04- ESPAÇO DE EVENTOS E CAFETERIA (40 lugares)</p>
					</div>
				</div>
				<div className='plans-options'>
					<div className='plan-option'>
						<img
							src={sharedDeskImage}
							alt='Mesa Compartilhada'
						/>
						<div className='plan-detail'>
							<div className='plan-description'>
								<h3>mesa compartilhada</h3>
								<p>
									<span>R$ 990</span> (anual)
								</p>
							</div>
						</div>
					</div>
					<div className='plan-option'>
						<img
							src={privateDeskImage}
							alt='Mesas Privativas'
						/>
						<div className='plan-detail'>
							<div className='plan-description'>
								<h3>mesas Privativas</h3>
								<p>
									<span>R$ 1.990</span> (anual)
								</p>
							</div>
						</div>
					</div>
					<div className='plan-option'>
						<img
							src={meetingRoomImage}
							alt='Sala de Reunião'
						/>
						<div className='plan-detail'>
							<div className='plan-description'>
								<h3>sala de reunião privativa</h3>
								<p>
									<span>R$ 2.990</span> (anual)
								</p>
							</div>
						</div>
					</div>
					<div className='plan-option'>
						<img
							src={coffeeImage}
							alt='Café e Eventos'
						/>
						<div className='plan-detail'>
							<div className='plan-description'>
								<h3>café, coworking e seus eventos</h3>
								<p>
									<span>R$ 10.000</span> (anual)
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Plans;
