// src/components/AlmocoPage.js
import React from 'react';
import './AlmocoPage.css';
import almocoBanner from './assets/almoco/almoco-banner.png';
import pratoSegunda from './assets/almoco/segunda.png';
import pratoTerca from './assets/almoco/terca.png';
import pratoQuarta from './assets/almoco/quarta.png';
import pratoQuinta from './assets/almoco/quinta.png';
import pratoSexta from './assets/almoco/sexta.png';
import todosOsDias from './assets/almoco/todos-os-dias.png';
import entregaImagem from './assets/almoco/entrega.png';
import ifoodImagem from './assets/almoco/ifood.png';

const AlmocoPage = () => {
	return (
		<>
			<img
				src={almocoBanner}
				alt='Cardápio de Almoço'
				className='almoco-banner'
			/>
			<div className='almoco-page'>
				<div className='side-img' />
				<main>
					<h2>Cardápio de Almoço</h2>
					<div className='almoco-grid'>
						<div className='almoco-item'>
							<img
								src={pratoSegunda}
								alt='Segunda-feira'
							/>
							<p>segunda-feira</p>
							<ul>
								<li>Yakisoba de carne</li>
								<li>Salada P (alface, tomate, cenoura ralada e pepinos)</li>
							</ul>
						</div>
						<div className='almoco-item'>
							<img
								src={pratoTerca}
								alt='Terça-feira'
							/>
							<p>terça-feira</p>
							<ul>
								<li>02 Panquecas a bolonhesa</li>
								<li>Salada P (alface, tomate, cenoura ralada e pepinos)</li>
							</ul>
						</div>
						<div className='almoco-item'>
							<img
								src={pratoQuarta}
								alt='Quarta-feira'
							/>
							<p>quarta-feira</p>
							<ul>
								<li>Risoto de frango e parmesão</li>
								<li>Salada P (alface, tomate, cenoura ralada e pepinos)</li>
							</ul>
						</div>
						<div className='almoco-item'>
							<img
								src={pratoQuinta}
								alt='Quinta-feira'
							/>
							<p>quinta-feira</p>
							<ul>
								<li>Frango xadrez</li>
								<li>Salada P (alface, tomate, cenoura ralada e pepinos)</li>
							</ul>
						</div>
						<div className='almoco-item'>
							<img
								src={pratoSexta}
								alt='Sexta-feira'
							/>
							<p>sexta-feira</p>
							<ul>
								<li>Strogonoff de carne</li>
								<li>Salada P (alface, tomate, cenoura ralada e pepinos)</li>
							</ul>
						</div>
						<div className='almoco-item'>
							<img
								src={todosOsDias}
								alt='Todos os dias'
							/>
							<p>Todos os dias</p>
							<ul>
								<li>Salada G de carne ou frango</li>
								<li>Alface, cenoura ralada, tomates, pepino, e parmesão</li>
							</ul>
						</div>
					</div>
					<div className='entrega-info'>
						<p>Dias de semana: entrega grátis até 1km do Link Café</p>
						<img
							src={entregaImagem}
							alt='Entrega grátis até 1km'
						/>
					</div>
					<div className='ifood-info'>
						<p>
							Pedido e entrega para toda cidade acessando nossa loja no iFood
						</p>
						<img
							src={ifoodImagem}
							alt='iFood'
						/>
					</div>
				</main>
				<div className='side-img' />
			</div>
		</>
	);
};

export default AlmocoPage;
