// src/components/BusinessHub.js
import React from 'react';
import './BusinessHub.css';
import cafeteria from './assets/cafeteria.png';
import events from './assets/events.png';
import coworking from './assets/coworking.png';
import commerce from './assets/commerce.png';
import SideImage from './assets/side.png';

function BusinessHub() {
	return (
		<div className='business-hub-container'>
			<div>
				<img
					src={SideImage}
					alt='SideImage'
					className='side-img points'
				/>
			</div>
			<section className='business-hub'>
				<h2>HUB DE CONEXÕES & NEGÓCIOS</h2>
				<div className='hub-items'>
					<div className='hub-item'>
						<img
							src={cafeteria}
							alt='Cafeteria'
							className='hub-image'
						/>
						<div className='hub-description'>
							<div className='hub-detail'>
								<div className='ball pink' />
								<h3>cafeteria</h3>
							</div>
							<p>
								Cafés especiais, doces e salgados, conheça nosso delicioso
								cardápio.
							</p>
						</div>
					</div>
					<div className='hub-item'>
						<img
							src={coworking}
							alt='Coworking'
						/>
						<div className='hub-description'>
							<div className='hub-detail'>
								<div className='ball blue' />
								<h3>coworking</h3>
							</div>
							<p>
								Reserve agora um dos nossos 04 espaços para trabalhar com
								conforto.
							</p>
						</div>
					</div>
					<div className='hub-item'>
						<img
							src={events}
							alt='Eventos'
						/>
						<div className='hub-description'>
							<div className='hub-detail'>
								<div className='ball yellow' />
								<h3>eventos</h3>
							</div>
							<p>
								Espaço perfeito para seu evento brilhar com menu especial e toda
								estrutura.
							</p>
						</div>
					</div>
					<div className='hub-item'>
						<img
							src={commerce}
							alt='E-commerce'
						/>
						<div className='hub-description'>
							<div className='hub-detail'>
								<div className='ball sand' />
								<h3>e-commerce</h3>
							</div>
							<p>
								Acesse a LinkStore para conhecer e comprar os produtos da nossa
								loja.
							</p>
						</div>
					</div>
				</div>
			</section>
			<div>
				<img
					src={SideImage}
					alt='SideImage'
					className='side-img points'
				/>
			</div>
		</div>
	);
}

export default BusinessHub;
