import centroPartnerLogo from '../components/assets/partners/centro.png';
import copelPartnerLogo from '../components/assets/partners/copel.png';
import rdStationPartnerLogo from '../components/assets/partners/rdstation.png';
import t2iGroupPartnerLogo from '../components/assets/partners/t2igroup.png';

export const partners = [
	{
		src: centroPartnerLogo,
		alt: 'O Centro',
	},
	{
		src: copelPartnerLogo,
		alt: 'Fundação Copel',
	},
	{
		src: rdStationPartnerLogo,
		alt: 'RD Station',
	},
	{
		src: t2iGroupPartnerLogo,
		alt: 'T2I Group',
	},
	// {
	// 	src: schoolPartnerLogo,
	// 	alt: 'Programa Escola De Turismo | Curitiba PR',
	// },
];
