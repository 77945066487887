// src/components/QuemSomos.js
import React from 'react';
import './QuemSomos.css';
import historiaImage from './assets/historia.png';
import pitchImage from './assets/pitch.png';

const QuemSomos = () => {
	return (
		<div className='quem-somos'>
			{/* Conteúdo principal */}
			<img
				src={historiaImage}
				alt='História'
				className='historia-image'
			/>
			<div className='historia-container'>
				<div className='side-img' />
				<main>
					<section className='historia'>
						<h2>Nossa História</h2>
						<p>
							O <strong>Link Café Hub</strong> é uma{' '}
							<strong>startup curitibana</strong> que abriga espaço de coworking
							localizado no Centro Histórico da cidade, conhecido por oferecer
							um ambiente moderno e colaborativo para profissionais e
							empreendedores. Ele combina elementos de café e coworking,
							proporcionando um ambiente descontraído e produtivo. O espaço é
							projetado para atender às necessidades de diferentes tipos de
							trabalhadores, desde freelancers até pequenas empresas.
						</p>
						<p>
							Além das estações de trabalho compartilhadas e salas privativas, o{' '}
							<strong>Link Café Hub</strong> frequentemente organiza eventos,
							workshops e palestras, promovendo a troca de conhecimento e
							networking entre seus membros. A área de café serve uma variedade
							de bebidas e comidas, criando um ambiente que incentiva tanto o
							trabalho quanto o lazer.
						</p>
					</section>
					<section className='valores'>
						<h2>Fazemos parte do Vale do Pinhão, com muito orgulho!</h2>
						<p>
							O <strong>Vale do Pinhão</strong> é um ecossistema de inovação
							localizado em Curitiba, Paraná, Brasil, e não um vale físico, como
							o nome pode sugerir. Ele é uma iniciativa que busca fomentar o
							desenvolvimento de startups e empresas de tecnologia na região. A
							seguir estão alguns aspectos importantes sobre as startups no Vale
							do Pinhão:
						</p>
						<ul>
							<li>
								<strong>1. "Inovação e Tecnologia":</strong> As startups do Vale
								do Pinhão estão geralmente focadas em áreas como tecnologia da
								informação, inteligência artificial, fintechs, saúde digital,
								e-commerce e soluções sustentáveis. Essas empresas
								frequentemente buscam transformar ideias inovadoras em produtos
								e serviços de alto impacto.
							</li>
							<li>
								<strong>2. "Apoio e Incubação":</strong> O ecossistema oferece
								uma série de programas de apoio, incluindo incubadoras e
								aceleradoras, que ajudam startups a se desenvolverem. Estes
								programas fornecem mentoria, recursos financeiros, espaço físico
								e conexões valiosas para empreendedores.
							</li>
							<li>
								<strong>3. "Ambiente de Colaboração":</strong> O Vale do Pinhão
								é conhecido por seu ambiente colaborativo, onde startups,
								investidores, universidades e instituições de pesquisa trabalham
								juntos para criar uma rede de suporte e inovação. Eventos,
								meetups e hackathons são comuns e ajudam a promover o networking
								e a troca de ideias.
							</li>
							<li>
								<strong>4. "Infraestrutura":</strong> A região conta com
								infraestrutura adequada para apoiar o crescimento de startups,
								incluindo espaços de coworking, centros de inovação e acesso a
								tecnologias de ponta.
							</li>
							<li>
								<strong>5. "Parcerias e Investimentos":</strong> Startups no
								Vale do Pinhão frequentemente se beneficiam de parcerias
								estratégicas com grandes empresas e investidores que buscam
								apoiar novas iniciativas e explorar inovações emergentes.
							</li>
							<li>
								<strong>6. "Exemplos de Startups":</strong> O Vale do Pinhão
								abriga diversas startups de destaque em várias áreas. Exemplos
								podem incluir empresas que desenvolvem soluções em saúde
								digital, plataformas de e-commerce, ferramentas de automação
								para negócios, e startups de impacto social.
							</li>
							<li>
								<strong>7. "Educação e Formação":</strong> Muitas startups da
								região colaboram com instituições acadêmicas locais para
								fomentar o talento e a pesquisa. Programas de estágios e
								educação são promovidos para preparar novos empreendedores e
								profissionais para o mercado de tecnologia.
							</li>
						</ul>
						<p>
							O Vale do Pinhão tem se consolidado como um hub importante para a
							inovação no Brasil, proporcionando um ambiente vibrante e
							estimulante para startups e empreendedores.
						</p>
						<h2>Link Café no Pitch Live 2024</h2>
					</section>
				</main>
				<div className='side-img' />
			</div>

			<section className='pitch-live'>
				<img
					src={pitchImage}
					alt='Pitch Live 2024'
					className='pitch-image'
				/>
			</section>
		</div>
	);
};

export default QuemSomos;
