import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import BusinessHub from './components/BusinessHub';
import Plans from './components/Plans';
import Location from './components/Location';
import Footer from './components/Footer';
import MenuPage from './components/MenuPage';
import QuemSomos from './components/QuemSomos';
import AlmocoPage from './components/AlmocoPage';
import LinkStorePage from './components/LinkStorePage';
import PhotoGallery from './components/PhotoGallery';
import { photos } from './utils/photos';
import whatsappIcon from './components/assets/icons/whats.svg'; 

function App() {
	const [showWhatsApp, setShowWhatsApp] = useState(false);

	
	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.pageYOffset; 
			if (scrollPosition > 100) {
				setShowWhatsApp(true);
			} else {
				setShowWhatsApp(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Router>
			<Header />
			<Routes>
				<Route
					path='/'
					element={
						<>
							<PhotoGallery photos={photos} />
							<BusinessHub />
							<Plans />
							<Location />
						</>
					}
				/>
				<Route path='/menu' element={<MenuPage />} />
				<Route path='/quem-somos' element={<QuemSomos />} />
				<Route path='/almoco' element={<AlmocoPage />} />
				<Route path='/linkstore' element={<LinkStorePage />} />
			</Routes>
			<Footer />
			
			{/* Ícone flutuante do WhatsApp */}
			<a
				href='https://api.whatsapp.com/message/5M5BJ5DLQEZ4M1?autoload=1&app_absent=0'
				target='_blank'
				rel='noopener noreferrer'
				className={`whatsapp-float ${showWhatsApp ? 'show' : ''}`}
			>
				<img src={whatsappIcon} alt='WhatsApp' />
			</a>
		</Router>
	);
}

export default App;
