import React from 'react';
import './Location.css';

import location from './assets/location.png';

import Star from './assets/icons/star.svg';
import People from './assets/icons/people.svg';

const Location = () => {
	return (
		<div className='location-container'>
			<h2>Localização</h2>

			<div className='location-section'>
				<div className='location-wrapper'>
					<img
						src={location}
						alt='Location'
					/>
					<div className='location-map'>
						<iframe
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5481.9620205777455!2d-49.27566917563735!3d-25.425921596834854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce57a2fcacbdd%3A0x634678d9faddc4eb!2sLink%20Caf%C3%A9%20-%20Hub%20de%20neg%C3%B3cios%20e%20conex%C3%B5es!5e0!3m2!1spt-BR!2sbr!4v1724704030971!5m2!1spt-BR!2sbr'
							allowfullscreen=''
							loading='lazy'
							referrerpolicy='no-referrer-when-downgrade'
							title='Location Map'
						></iframe>
					</div>
				</div>

				<div className='location-info'>
					<h2>Link Café Hub</h2>
					<p>Espaço de coworking cafeteria & eventos</p>

					<div className='location-boxes'>
						<div className='box rating'>
							{Array.from({ length: 5 }).map((_, index) => (
								<img
									src={Star}
									alt='star'
									className='star'
								/>
							))}
							<span>4,9 (20 avaliações)</span>
						</div>
						<div className='box users'>
							<img
								src={People}
								alt='People'
								className='star'
							/>
							<span>4.862 visualizações</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Location;
